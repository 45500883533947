import React from 'react';
import Layout from '../layouts/claire';
import { Link } from 'react-router';
import { Animated } from 'react-animated-css';
import config from '../../config';
import Menu from '../partials/menu';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import TopRightMenu from '../partials/top_right_menu';
import fakeAuth from '../../fakeAuth';
import moment from 'moment';
import BlackOverlay from '../partials/black_overlay';
import LineLoader from '../partials/line_loader';
import _ from 'lodash';
import DatePicker from 'react-datepicker';
import CustomDatePicker from '../partials/custom_date_picker';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

class Forecast extends React.Component {
    state = {
        menu_toggled: false,
        toggle: 1,
        rooms_selected: [],
        floors: [],
        status: config.room_status,
        sort: 'CreationDate',
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null,
        filters: localStorage.getItem('lb.reports.filters') ? {
            CheckIn: JSON.parse(localStorage.getItem('lb.reports.filters')).CheckIn ? moment(JSON.parse(localStorage.getItem('lb.reports.filters')).CheckIn).utc() : null,
            CheckOut: JSON.parse(localStorage.getItem('lb.reports.filters')).CheckOut ? moment(JSON.parse(localStorage.getItem('lb.reports.filters')).CheckOut).utc() : null,
            Code: JSON.parse(localStorage.getItem('lb.reports.filters')).Code || null,
            IdRoom: null,
        } : {
            CheckIn: moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').utc(),
            CheckOut: moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').add(10, 'days').utc(),
            IdRoom: null
        },
    }

    // Lifecycle
    componentWillMount() {
        const { params, } = this.props;
        let user = fakeAuth.getUser();
        if (user.IdProperty) this.props.router.goBack();
        else {
            this.props.properties_actions && this.props.properties_actions.fetch_one(params.id);
            this.props.floors_actions && this.props.floors_actions.fetch(params.id);
            this.props.rooms_actions && this.props.rooms_actions.fetch(params.id);
        }
    }
    componentWillReceiveProps = nextProps => {
        const { floors = [], bookings, rooms } = nextProps;

        // resources is basically the list of actual pieces
        let resources = [];
        if (rooms) {

            _.sortBy(floors, 'Label').forEach(floor => {
                _.sortBy(floor.Pieces, 'Label').forEach((piece, i) => {

                    resources.push({
                        status: piece.Status,
                        id: piece.IdPiece,
                        floor: floor.Label,
                        title: piece.Label,
                        name: (_.find(rooms, r => r.IdRoom === piece.IdRoom) || {}).GivenName,
                        IdRoom: (_.find(rooms, r => r.IdRoom === piece.IdRoom) || {}).IdRoom,
                        occupancy: 0, // TODO: ?
                    })
                });
            });

            rooms.forEach(r => {
                r.TOTAL = _.filter(resources, x => x.IdRoom === r.IdRoom).length
            });

            this.setState({
                floors,
                bookings,
                all_booked_rooms: _.chain(bookings).map(b => b.Rooms).flatten().compact().value(),
                resources,
                rooms: [{
                    GivenName: `Todas`,
                    TOTAL: resources.length,
                    IdRoom: null
                }, ...rooms],
                bootstrapped: bookings ? true : false
            });
        }


    }
    //

    onFetch = () => {
        this.props.booking_actions && this.props.booking_actions.fetch_range({
            IdProperty: this.props.params.id,
            CheckIn: moment(this.state.filters.CheckIn).format('YYYY-MM-DD'),
            CheckOut: moment(this.state.filters.CheckOut).format('YYYY-MM-DD')
        });
    }
    doDelete = () => {
        const { id } = this.props.params;
        this.props.guests_actions && this.props.guests_actions.archive(this.state.delete_id, id);
        this.setState({ delete_id: null });
    }

    // RENDER
    renderHeader = () => {
        return <div className="d-flex">
            <div className="w-100 d-flex ">
                <i className="icon-lb_reportes blue font-40 align-self-center" />
                <div className="align-self-center d-flex flex-column">
                    <span className="white ms-3 font-20 line1 poppins">Reportes - Forecast</span>
                    <Link to="/properties" className="white ms-3 font-10 poppins underlined">{this.state.hotel_name}</Link>
                </div>
            </div>
            {/*<div className="w-100">
                <div className="bg-gray-2 black-input d-flex">
                    <i className="fas fa-search font-16 white align-self-center ps-3" />
                    <input className="w-100 p-3 white" type="text" placeholder="Buscar en Legendbeds" ref={(input) => this.txtSearch = input} onChange={e => this.setState({ search: e.target.value })} />
                </div>
            </div>*/}
            <div className="w-100 d-flex align-self-center">
                <TopRightMenu {...this.props} />
            </div>
        </div>
    }
    renderFilters = () => {
        const { filters, rooms } = this.state;

        return <Animated animationIn="fadeInRight" animationInDelay={200} animationInDuration={200} className="w3-content d-flex justify-content-center mt-4" style={{ zIndex: 200, position: 'relative' }}>

            {/* Start */}
            <div style={{ width: 150 }}>
                <label className="white poppins font-12">Fechas</label>
                <div id="txtPeriodStart">
                    <DatePicker selected={this.state.filters.CheckIn} highlightDates={[]} customInput={<CustomDatePicker format="DD/MM/YYYY" hint="Inicio" />} showYearDropdown scrollableYearDropdown maxDate={this.state.filters.CheckOut}
                        yearDropdownItemNumber={15} onChange={date => {
                            const { filters } = this.state;
                            filters.CheckIn = moment(date).utc();
                            this.setState({ filters });
                        }} className={`white font-12 ${filters.CheckIn ? 'dirty' : ''}`}
                    />
                </div>
            </div>
            <div className="p-1" />
            {/* End */}
            <div style={{ width: 150 }}>
                <label className="white poppins font-12"></label>
                <div id="txtPeriodEnd">
                    <DatePicker selected={this.state.filters.CheckOut} highlightDates={[]} customInput={<CustomDatePicker format="DD/MM/YYYY" hint="Fin" clear={() => {
                        const { filters } = this.state;
                        filters.CheckOut = null;
                        this.setState({ filters });
                    }} />} showYearDropdown scrollableYearDropdown minDate={this.state.filters.CheckIn}
                        yearDropdownItemNumber={15} onChange={date => {
                            const { filters } = this.state;
                            filters.CheckOut = moment(date).utc();
                            this.setState({ filters });
                        }} className={`white font-12 ${filters.CheckOut ? 'dirty' : ''}`}
                    />
                </div>
            </div>
            <div className="p-1" />
            {/* Room Type */}
            {rooms.length > 0 && <div style={{ width: 200 }}>
                <label className="white poppins font-12">Habitación</label>
                <div id="txtType" className={`black-input  d-flex mt-2`}>
                    <Dropdown isOpen={this.state.pickType} toggle={() => this.setState({ pickType: !this.state.pickType })} className="w-100">
                        <DropdownToggle style={{ paddingTop: 0, paddingBottom: 0, backgroundColor: 'transparent' }} className="w-100 p-3 white bg-transparent d-flex">
                            <span className="align-self-center">{_.find(rooms, r => r.IdRoom === filters.IdRoom).GivenName} ({_.find(rooms, r => r.IdRoom === filters.IdRoom).TOTAL})</span>
                            <i className="fas fa-chevron-down font-16 white align-self-center ps-3 ms-auto" />
                        </DropdownToggle>
                        <DropdownMenu className="w-100">
                            {rooms.map((c, i) => <DropdownItem key={i} onClick={() => {
                                const { filters } = this.state;
                                filters.IdRoom = c.IdRoom;
                                this.setState({ filters });
                            }}>{c.GivenName} ({c.TOTAL})</DropdownItem>)}
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>}
            <div className="p-1" />
            <button onClick={this.onFetch} className="btn btn-lg btn-link white align-self-end d-flex bordered">
                <i className="fas fa-search blue font-18 my-2 align-self-center" />
            </button>
            {/*this.state.bootstrapped && this.state.all_booked_rooms && this.state.all_booked_rooms.length > 0 && <button className="ms-2 btn btn-lg btn-link white align-self-end d-flex bordered">
                <i className="fas fa-download blue font-18 my-2 align-self-center" />
            </button>*/}
            {this.state.bootstrapped && this.state.all_booked_rooms && this.state.all_booked_rooms.length > 0 && <ReactHTMLTableToExcel id="test-table-xls-button" className="ms-auto btn btn-lg btn-link white align-self-end d-flex bordered" table="table-to-xls" filename={`Forecast`}
            sheet="Admins" buttonText="Exportar" />}
        </Animated>
    }
    renderDay = (date, index) => {

        // Here we do some calculations
        // FIRST: we get all the bookings that happens that day
        const { all_booked_rooms, filters, rooms } = this.state;

        let today_booked_rooms = _.chain(all_booked_rooms)
            .filter(r => moment(date).isBetween(moment(r.CheckIn), moment(r.CheckOut)))
            .filter(r => filters.IdRoom ? r.IdRoom === (filters.IdRoom) : true)
            .value(),
            selected_room = _.find(rooms, r => r.IdRoom === filters.IdRoom);

        let tomorrow_booked_rooms = _.chain(all_booked_rooms)
            .filter(r => moment(date).add(1, 'day').isBetween(moment(r.CheckIn), moment(r.CheckOut)))
            .filter(r => filters.IdRoom ? r.IdRoom === (filters.IdRoom) : true)
            .value();

        let entradas = _.chain(all_booked_rooms)
            .filter(r => moment(date).isSame(moment(r.CheckIn), 'day'))
            .filter(r => filters.IdRoom ? r.IdRoom === (filters.IdRoom) : true)
            .value();

        let salidas = _.chain(all_booked_rooms)
            .filter(r => moment(date).isSame(moment(r.CheckOut), 'day'))
            .filter(r => filters.IdRoom ? r.IdRoom === (filters.IdRoom) : true)
            .value();

        return <tr key={index} className="font-8 text-center">
            <td>{date.Date}</td>

            {/* The very first day of the table we cannot determine the previous state? */}
            <td className='w3-blue'>{today_booked_rooms.length}</td>
            <td className='w3-blue'>{_.chain(today_booked_rooms).map(r => r.Adults).reduce((a, b) => a + b, 0).value()}</td>
            <td className='w3-blue'>{_.chain(today_booked_rooms).map(r => r.Children).reduce((a, b) => a + b, 0).value()}</td>
            <td className='w3-blue'>{_.chain(today_booked_rooms).map(r => r.Cribs).reduce((a, b) => a + b, 0).value()}</td>

            <td className={`w3-green ${entradas.length ? '' : 'transparent'}`}>{entradas.length}</td>
            <td className={`w3-green ${_.chain(entradas).map(r => r.Adults).reduce((a, b) => a + b, 0).value() ? '' : 'transparent'}`}>{_.chain(entradas).map(r => r.Adults).reduce((a, b) => a + b, 0).value()}</td>
            <td className={`w3-green ${_.chain(entradas).map(r => r.Children).reduce((a, b) => a + b, 0).value() ? '' : 'transparent'}`}>{_.chain(entradas).map(r => r.Children).reduce((a, b) => a + b, 0).value()}</td>
            <td className={`w3-green ${_.chain(entradas).map(r => r.Cribs).reduce((a, b) => a + b, 0).value() ? '' : 'transparent'}`}>{_.chain(entradas).map(r => r.Cribs).reduce((a, b) => a + b, 0).value()}</td>

            <td className={`w3-yellow ${salidas.length ? '' : 'transparent'}`}>{salidas.length}</td>
            <td className={`w3-yellow ${_.chain(salidas).map(r => r.Adults).reduce((a, b) => a + b, 0).value() ? '' : 'transparent'}`}>{_.chain(salidas).map(r => r.Adults).reduce((a, b) => a + b, 0).value()}</td>
            <td className={`w3-yellow ${_.chain(salidas).map(r => r.Children).reduce((a, b) => a + b, 0).value() ? '' : 'transparent'}`}>{_.chain(salidas).map(r => r.Children).reduce((a, b) => a + b, 0).value()}</td>
            <td className={`w3-yellow ${_.chain(salidas).map(r => r.Cribs).reduce((a, b) => a + b, 0).value() ? '' : 'transparent'}`}>{_.chain(salidas).map(r => r.Cribs).reduce((a, b) => a + b, 0).value()}</td>

            <td className={`w3-pink ${tomorrow_booked_rooms.length ? '' : 'transparent'}`}>{tomorrow_booked_rooms.length}</td>
            <td className={`w3-pink ${_.chain(tomorrow_booked_rooms).map(r => r.Adults).reduce((a, b) => a + b, 0).value() ? '' : 'transparent'}`}>{_.chain(tomorrow_booked_rooms).map(r => r.Adults).reduce((a, b) => a + b, 0).value()}</td>
            <td className={`w3-pink ${_.chain(tomorrow_booked_rooms).map(r => r.Children).reduce((a, b) => a + b, 0).value() ? '' : 'transparent'}`}>{_.chain(tomorrow_booked_rooms).map(r => r.Children).reduce((a, b) => a + b, 0).value()}</td>
            <td className={`w3-pink ${_.chain(tomorrow_booked_rooms).map(r => r.Cribs).reduce((a, b) => a + b, 0).value() ? '' : 'transparent'}`}>{_.chain(tomorrow_booked_rooms).map(r => r.Cribs).reduce((a, b) => a + b, 0).value()}</td>

            <td>{(tomorrow_booked_rooms.length * 100 / selected_room.TOTAL).toFixed(0)}%</td>
            <td>{selected_room.TOTAL - tomorrow_booked_rooms.length}</td>
            <td>{(100 - (tomorrow_booked_rooms.length * 100 / selected_room.TOTAL)).toFixed(0)}%</td>
            {/*<td>??</td>*/}
        </tr>
    }
    renderTable = () => {

        // IOC
        let dates = [];
        const { filters, resources } = this.state, { CheckIn, CheckOut } = filters;

        let currentDate = moment(CheckIn), finalDate = moment(CheckOut);
        while (currentDate.isSameOrBefore(finalDate)) {
            dates.push({
                Date: currentDate.format('DD/MM/YYYY')
            })
            currentDate.add(1, 'days');
        }

        return <table className="table" id="table-to-xls">
            <thead>
                <tr>
                    <th>Fecha</th>
                    <th colSpan={4} className="text-center">Inicial</th>
                    <th colSpan={4} className="text-center">Entradas</th>
                    <th colSpan={4} className="text-center">Salidas</th>
                    <th colSpan={4} className="text-center">Final</th>
                    <th colSpan={4} className="text-center">Estadisticas</th>
                </tr>
            </thead>
            <tbody>
                {/* HEADER */}
                <tr className="font-8">
                    <td></td>

                    <td>Rooms</td>
                    <td>Guests</td>
                    <td>Child</td>
                    <td>Infant</td>

                    <td>Rooms</td>
                    <td>Guests</td>
                    <td>Child</td>
                    <td>Infant</td>

                    <td>Rooms</td>
                    <td>Guests</td>
                    <td>Child</td>
                    <td>Infant</td>

                    <td>Rooms</td>
                    <td>Guests</td>
                    <td>Child</td>
                    <td>Infant</td>

                    <td>%Ocu</td>
                    <td>Disp</td>
                    <td>%Disp</td>
                    {/*<td>F/U</td>*/}
                </tr>
                {/* DATA */}
                {dates.map((date, index) => this.renderDay(date, index))}
            </tbody>
            <tfoot>
                <tr>
                    <td colSpan={20} className="text-right">
                        F/U: {_.chain(resources)
                        .filter(r => filters.IdRoom ? r.IdRoom === (filters.IdRoom) : true)
                        .filter(r => ((r.status & 2048) || (r.status & 64) || (r.status & 32))).value().length}
                    </td>
                </tr>
            </tfoot>
        </table>

        /*room_status: [
            { id: 1, name: 'Limpia', icon: 'icon-lb_housekeep', color: '#00C6FE', pow: 1 },
            { id: 2, name: 'Sucia', icon: 'icon-lb_housekeep', color: '#B4B878', pow: 2 },
            { id: 3, name: 'Disponible', icon: 'icon-lb_housekeep', color: '#05F300', macro: true, pow: 4 },
            { id: 4, name: 'Ocupada', icon: 'icon-lb_housekeep', color: '#FE001D', macro: true, pow: 8 },
            { id: 5, name: 'Reservada', icon: 'icon-lb_housekeep', color: '#1771FF', macro: true, pow: 16 },
            { id: 6, name: 'Reparar', icon: 'icon-lb_housekeep', color: '#FDF403', pow: 32 },
            { id: 7, name: 'DNR', icon: 'icon-lb_housekeep', color: '#FF640D', pow: 64 },
            { id: 8, name: 'Inspección', icon: 'icon-lb_housekeep', color: '#FF1AC7', pow: 128 },
            { id: 9, name: 'Estadia noche', icon: 'icon-lb_housekeep', color: '#848ECE', pow: 256 },
            { id: 10, name: 'Uso de casa', icon: 'icon-lb_housekeep', color: '#BEE7FE', pow: 512 },
            { id: 11, name: 'Check-out', icon: 'icon-lb_housekeep', color: '#6E44FF', pow: 1024 },
            { id: 12, name: 'No disponible', icon: 'icon-lb_housekeep', color: '#991A00', pow: 2048 },
        ],*/
    }
    renderCommon = () => {
        const { filters } = this.state;
        return (
            <div className="">
                {this.renderHeader()}
                <div className="d-flex justify-content-center mt-4">
                    <Link to={`/reports/occupation/${this.props.params.id}`} className="btn btn-link bg-gray-2" style={{ width: 80 }}><i className="fas fa-percent font-20 whiteish" /></Link>
                    {/*<Link to={`/reports/transactions/${this.props.params.id}`} className="ms-2 btn btn-link bg-gray-2" style={{ width: 80 }}><i className="fas fa-dollar font-20 whiteish" /></Link>*/}
                    <Link to={`/reports/forecast/${this.props.params.id}`} className="ms-2 btn btn-link bg-gray-2" style={{ width: 80 }}><i className="fas fa-cloud font-20 blue" /></Link>
                    <Link to={`/reports/guests/${this.props.params.id}`} className="ms-2 btn btn-link bg-gray-2" style={{ width: 80 }}><i className="fas fa-id-card font-20 whiteish" /></Link>
                </div>


                <div style={{ position: "absolute", top: '12em', bottom: 0, left: 100, right: 0, overflow: "auto" }} className="bg-gray-dark inner-shadow p-2">

                    {this.state.rooms && this.renderFilters()}

                    {/* Sheet */}
                    {this.state.bootstrapped && this.state.all_booked_rooms && this.state.all_booked_rooms.length > 0 && <Animated animationIn="fadeInUp" animationInDuration={200} className="sheet margin-top p-2" style={{ borderRadius: 2, width: 'fit-content', minWidth: 300, margin: 'auto' }}>
                        {this.renderTable()}
                    </Animated>}
                </div>
            </div>
        )
    }
    render() {
        const form = this.props.form_actions;
        const { rooms_selected, status } = this.state;

        return (
            <Layout router={this.props.router} nbs="home" title="legendbeds" form={this.props.form} form_actions={this.props.form_actions}>
                <section>

                    {this.state.microTransac && <LineLoader />}

                    {/* DESKTOP */}
                    <div className="w3-container w3-hide-small " style={{ marginLeft: 100 }}>
                        <div style={{ height: 50 }} />
                        {this.renderCommon()}
                    </div>
                    {/* MOBILE */}
                    {/*<div className="w3-container w3-hide-medium w3-hide-large">
                        <div style={{ height: 65 }} />
                        {this.renderCommon()}
        </div>*/}

                    <Menu {...this.state} {...this.props} />

                    {/* Delete Confirm */}
                    <Animated animateOnMount={false} animationIn='fadeIn' animationInDuration={200} animationOutDuration={200} animationOut='fadeOut' isVisible={this.state.delete_id ? true : false}>
                        <BlackOverlay />
                        <div className="d-flex justify-content-center" style={{ position: 'fixed', top: 0, bottom: 0, left: 100, right: 0, zIndex: 1 }}>
                            <div className="black-input bg-gray-2 p-4 align-self-center">
                                <h3 className="white poppins text-center">Confirmar operación</h3>
                                <h6 className="blue text-center poppins">Eliminar huesped</h6>
                                <hr className="w-50 mx-auto" />
                                <h6 className="white text-center poppins">¿Está seguro de eliminar este empleado?</h6>
                                <div className="d-flex justify-content-center mt-4">
                                    <button onClick={() => this.setState({ delete_id: false })} type="button" className="btn btn-lg btn-gray-2 white ms-3 align-self-center" >
                                        <i className="fas fa-times white font-12" />
                                    </button>
                                    <button onClick={this.doDelete} type="button" className="btn btn-lg btn-blue white ms-3 align-self-center" >
                                        <i className="fas fa-check white font-12" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Animated>
                </section>
            </Layout>
        )
    }
}

export default Forecast;
