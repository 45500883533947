import React from 'react';
import { render } from 'react-dom';
import { Router, Route, browserHistory, IndexRoute } from 'react-router';
import { Provider } from 'react-redux';
import { syncHistoryWithStore } from 'react-router-redux';

import store from './store';

// CSS
import './css/fonts.css';
import './css/buttons.css';
import './css/colors.css';
import './css/helpers.css';
import './css/layout.css';
import './css/bootstrap-translate.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
import './css/animations.css';
import './css/responsiveness.css';
import 'react-select/dist/react-select.css';
import "animate.css/animate.min.css";
import 'react-datepicker/dist/react-datepicker.css';

// Apps
import App_LEGENDBEDS from './apps/App.LEGENDBEDS';
import App_PRINTABLE from './apps/App.PRINTABLE.js';

// Common
import fakeAuth from './fakeAuth';
import HomeNew from './components/home/index.js';
import Login from './components/home/login.js';
import CreateAccount from './components/create_account';
import Properties from './components/properties';
import AddProperty from './components/properties/add';
import Recover from './components/home/recover';
import Housekeep from './components/housekeep';
import Staff from './components/staff';
import AddStaff from './components/staff/add';
import Rooms from './components/rooms';
import Plans from './components/plans';
import Frontdesk from './components/frontdesk';
import Bookings from './components/bookings/index.js';
import AddRoom from './components/rooms/add';
import EditRoom from './components/rooms/edit.js';
import Periods from './components/periods/index.js';
import AddPeriod from './components/periods/add.js';
import EditPeriod from './components/periods/edit.js';
import AddOffer from './components/offers/add.js';
import EditOffer from './components/offers/edit.js';
import AddPlan from './components/plans/add.js';
import EditPlan from './components/plans/edit.js';
import EditStaff from './components/staff/edit.js';
import Booking from './components/bookings/booking.js';
import AddBooking from './components/bookings/add.js';
import Floormap from './components/floormap/index.js';
import Guests from './components/guests';
import EditGuest from './components/guests/edit.js';
import Coorps from './components/coorps/index.js';
import AddCoorp from './components/coorps/add.js';
import EditCoorp from './components/coorps/edit.js';
import PrintableExample from './components/printable/example.js';
import Reports from './components/reports/index.js';
import Transactions from './components/reports/transactions.js';
import Forecast from './components/reports/forecast.js';
import Occupation from './components/reports/occupation.js';
import GuestsReport from './components/reports/guests.js';
import PrintableRegistration from './components/printable/registration.js';



const history = syncHistoryWithStore(browserHistory, store)

function requireAuth(nextState, replace) {
  if (!fakeAuth.isAuthenticated()) {
    replace({
      pathname: '/login',
      state: { nextPathname: nextState.location.pathname }
    })
    return;
  }
}

function requireAdmin(nextState, replace) {
  if (!fakeAuth.isAuthenticated() || !fakeAuth.isAdmin()) {
    replace({
      pathname: '/login',
      state: { nextPathname: nextState.location.pathname }
    })
  }
}

class Root extends React.Component {
  render() {
    return (
      <Provider store={store}>
        { /* SITEMAP? */}
        <Router history={history}>
          <Route path="/" component={App_LEGENDBEDS} onEnter={requireAuth}>
            <IndexRoute component={HomeNew} />
            <Route path="/dashboard/:id" component={HomeNew} />
            <Route path="/dashboard" component={HomeNew} />
            <Route path="/properties" component={Properties} />
            <Route path="/properties/add" component={AddProperty} />
            <Route path="/properties/edit/:id" component={AddProperty} />
            <Route path="/housekeep/:id" component={Housekeep} />
            <Route path="/housekeep" component={Housekeep} />
            <Route path="/staff/add/:id" component={AddStaff} />
            <Route path="/staff/edit/:id" component={EditStaff} />
            <Route path="/staff/:id" component={Staff} />
            <Route path="/staff" component={Staff} />

            <Route path="/coorps/edit/:id" component={EditCoorp} />
            <Route path="/coorps/add/:id" component={AddCoorp} />
            <Route path="/coorps/:id" component={Coorps} />
            <Route path="/coorps/:id/:letter" component={Coorps} />

            <Route path="/guest/:id" component={EditGuest} />
            <Route path="/guests/:id/:letter" component={Guests} />
            <Route path="/guests/:id" component={Guests} />

            <Route path="/reports/transactions/:id" component={Transactions} />
            <Route path="/reports/forecast/:id" component={Forecast} />
            <Route path="/reports/occupation/:id" component={Occupation} />
            <Route path="/reports/guests/:id" component={GuestsReport} />
            <Route path="/reports/:id" component={Reports} />

            <Route path="/rooms/:id" component={Rooms} />
            <Route path="/rooms" component={Rooms} />
            <Route path="/rooms/add/:id" component={AddRoom} />
            <Route path="/rooms/edit/:id" component={EditRoom} />
            <Route path="/periods/add/:id" component={AddPeriod} />
            <Route path="/periods/edit/:id" component={EditPeriod} />
            <Route path="/periods/:id" component={Periods} />
            <Route path="/periods" component={Periods} />
            <Route path="/offers/add/:id" component={AddOffer} />
            <Route path="/offers/edit/:id" component={EditOffer} />
            <Route path="/plans/add/:id" component={AddPlan} />
            <Route path="/plans/edit/:id" component={EditPlan} />
            <Route path="/plans/:id" component={Plans} />
            <Route path="/frontdesk/:id" component={Frontdesk} />
            <Route path="/frontdesk" component={Frontdesk} />

            <Route path="/floormap/:id" component={Floormap} />
            <Route path="/bookings/:id" component={Bookings} />
            <Route path="/booking/add/:id" component={AddBooking} />
            <Route path="/booking/:id" component={Booking} />
          </Route>
          <Route path="/create_account" component={App_LEGENDBEDS}>
            <IndexRoute component={CreateAccount} />
          </Route>
          <Route path="/login" component={App_LEGENDBEDS}>
            <IndexRoute component={Login} />
          </Route>
          <Route path="/recover" component={App_LEGENDBEDS}>
            <IndexRoute component={Recover} />
          </Route>
          <Route path="/print" component={App_PRINTABLE}>
            <IndexRoute component={Login} />
            <Route path="example/:id" component={PrintableExample} />
            <Route path="voucher/:id" component={PrintableExample} />
            <Route path="registration/:id/:index" component={PrintableRegistration} />
          </Route>
        </Router>
      </Provider>
    )
  }
}

render(<Root />, document.querySelector('#root'));
