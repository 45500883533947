import React from 'react';
import fakeAuth from '../../fakeAuth';
import moment from 'moment';
import _ from 'lodash';
import { formatPrice, padWithZeros, stripHTMLTags } from '../../helpers';

class PrintableRegistration extends React.Component {

    state = {
        tab: 1,
        menu_toggled: false,
        toggle: 1,
        sort: 'Name',
        filters: {
            CheckIn: moment().utc()
        },
        validations: {
            guest: false,
        },
        book: {
        },
        sources: [{ id: 1, value: 'Source 1' }, { id: 2, value: 'Source 2' }],
        hotel_name: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).Name : null,
        IdProperty: localStorage.getItem('lb.ip') ? JSON.parse(localStorage.getItem('lb.ip')).IdProperty : null,
    }

    // Lifecycle
    componentWillMount() {
        this.props.form_actions.fetch_countries();
        this.props.form_actions.fetch_country_codes();
        this.props.form_actions.fetch_nationalities();
        const { params } = this.props, { id } = params;
        if (id) {
            this.setup();
            this.props.floors_actions && this.props.floors_actions.fetch(this.state.IdProperty);
            this.props.coorps_actions && this.props.coorps_actions.fetch_all(fakeAuth.getUser().IdAccount);
            this.props.properties_actions && this.props.properties_actions.fetch_one(this.state.IdProperty);
        } else this.props.router.goBack();
    }
    componentWillReceiveProps = nextProps => {
        const { book, bookings, booking_actions, property, countries } = nextProps, { id } = this.props.params;
        if (book && book.IdBook && property) this.setState({ book }, () => {
            // NEW: here we need to get the 'occupancy of pieces of the day the reservation starts (or today if that day has already passed). 
            // Then we use that occupancy to rule out pieces for assigning due to -well- they are taken            
            if (!this.state.bootstrapped) {
                this.setState({ bootstrapped: true }, () => {
                    setTimeout(() => window && window.print(), 1000);
                });
            }
        });
    }
    //

    setup = () => {
        const { params } = this.props, { id } = params;
        this.props.booking_actions && this.props.booking_actions.fetch_one(id);
        this.props.accounting_actions && this.props.accounting_actions.fetch(id);
        this.props.accounting_actions && this.props.accounting_actions.fetch_payment_methods(id);
    }

    componentDidMount = () => {
        //
    }
    render() {

        const { book } = this.state, { accounting, property, params, countries } = this.props, { index } = params;
        if (!book.IdBook) return <section className="bg-white w3-container m-4" />

        let SUB_TOTAL = _.chain(accounting).reject(a => a.IsTaxFree).map(a => parseFloat(a.Debit)).reduce((a, b) => a + b, 0).value(),
            TAXES = SUB_TOTAL * (property ? (property.Tax || 0) : 0) / 100,
            OTHER_TAXES = 0, //_.chain(accounting).filter(a => a.IsTaxFree).map(a => parseFloat(a.Debit)).reduce((a, b) => a + b, 0).value(),
            NIGHTS = Math.abs(moment(book.CheckOut).utc().diff(moment(book.CheckIn).utc(), 'days')) + 1,
            PROMO = 0;

        //console.log({ index });
        let target_room = book.Rooms[parseInt(index, 10)];
        let all_people = [...book.Companions];
        target_room.COMPANIONS = _.filter(all_people, op => op.IdBookRoom === target_room.IdBookRoom);


        return <section className="bg-white w3-container m-3">
            {book && book.IdBook && <section className="w3-content">
                <div className="d-flex mt-4">
                    <div className="w-25">
                        <img style={{ maxHeight: 70 }} src={`${book.LogoUrl}?full=80`} />
                    </div>
                    <div className="w-50 text-center align-self-center">
                        <h4 className="align-self-center">TARJETA DE REGISTRO<br />REGISTRATION CARD</h4>
                    </div>
                    <div className="w-50 text-right align-self-center">
                        <h5 className="align-self-center">NO SE ACEPTAN CHECKES<br />
                            NO CHECKS ACCEPTED</h5>
                    </div>
                </div>
                <section className="font-7">
                    <div className="d-flex mt-2">
                        <figure className="card tile d-flex w-50 flex-column p-2 m-0">
                            <span>Localizador</span>
                            <span>{padWithZeros(book.PNR)}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Hab/Room</span>
                            <span>{target_room.PieceLabel}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Compañia/Companny</span>
                            <span>{book.Guest.Company || book.Guest.Coorp}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Rate</span>
                            {book.Rooms.map((r, i) => <span key={i}>{formatPrice(r.RateTotal / NIGHTS * 100)}</span>)}
                        </figure>
                        <figure className="card tile d-flex w-25 flex-column p-2 m-0">
                            <span className="font-6">Adultos</span>
                            <span>{target_room.Adults}</span>
                        </figure>
                        <figure className="card tile d-flex w-25 flex-column p-2 m-0">
                            <span className="font-6">Niños</span>
                            <span>{target_room.Children}</span>
                        </figure>
                        <figure className="card tile d-flex w-25 flex-column p-2 m-0">
                            <span className="font-6">Infantes</span>
                            <span>{target_room.Cribs}</span>
                        </figure>
                    </div>
                    <div className="d-flex mt-2">
                        <figure className="card tile d-flex w-50 flex-column p-2 m-0">
                            <span>Check-In</span>
                            <span>{moment(book.CheckIn).format('DD/MM/YYYY')}</span>
                        </figure>
                        <figure className="card tile d-flex w-50 flex-column p-2 m-0">
                            <span>Check-Out</span>
                            <span>{moment(book.CheckOut).format('DD/MM/YYYY')}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>
                                Nombre/Name
                            </span>
                            <span>{book.Guest.FirstName}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Apellido/Surname</span>
                            <span>{book.Guest.LastName}</span>
                        </figure>
                    </div>
                    <div className="d-flex mt-2">
                        {/* CEDULA O RIF SI ES EMPRESA */}
                        {book.IsGroup !== 1 && <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Cedula|Pasaporte</span>
                            <span>{book.Guest.IdOrPassport}</span>
                        </figure>}
                        {book.IsGroup === 1 && <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>ID | RIF</span>
                            <span>{book.Coorp.ID}</span>
                        </figure>}
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>E.Civil/Marital S.</span>
                            <span></span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Edad/Age</span>
                            <span>{book.Guest.DateOfBirth ? moment().diff(moment(book.Guest.DateOfBirth.split('T')[0], 'YYYY-MM-DD'), 'years') : '-'}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Nacionalidad/Nat.</span>
                            <span>{book.Guest.Nationality}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Profesión/Occupation</span>
                            <span>{book.Guest.Profession}</span>
                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Placa/Plate</span>
                            <span>{book.Guest.Profession}</span>
                        </figure>
                        {countries && book && <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Pais/Country</span>
                            <span>{_.find(countries, c => c.id === book.Guest.IdCountry).name}</span>
                        </figure>}
                    </div>
                    {book.IsGroup !== 1 && <div className="d-flex mt-2">
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Dirección</span>
                            <span>{book.Guest.Address}</span>
                        </figure>
                        <figure className="card tile d-flex w-50 flex-column p-2 m-0">
                            <span>Teléfono</span>
                            <span>{`${book.Guest.CellPhoneCountryCode}-${book.Guest.Cellphone}`}</span>
                        </figure>
                        <figure className="card tile d-flex w-50 flex-column p-2 m-0">
                            <span>Ciudad/City</span>
                            <span>{book.Guest.City}</span>
                        </figure>
                        <figure className="card tile d-flex w-50 flex-column p-2 m-0">
                            <span>Estado/State</span>
                            <span>{book.Guest.State}</span>
                        </figure>
                    </div>}
                    {book.IsGroup === 1 && <div className="d-flex mt-2">
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Dirección</span>
                            <span>{book.Coorp.Address}</span>
                        </figure>
                        <figure className="card tile d-flex w-50 flex-column p-2 m-0">
                            <span>Teléfono</span>
                            <span>{`${book.Coorp.PhoneCountryCode}-${book.Coorp.Phone}`}</span>
                        </figure>
                        <figure className="card tile d-flex w-50 flex-column p-2 m-0">
                            <span>Ciudad/City</span>
                            <span>{book.Coorp.City}</span>
                        </figure>
                        <figure className="card tile d-flex w-50 flex-column p-2 m-0">
                            <span>Estado/State</span>
                            <span>{book.Coorp.State}</span>
                        </figure>
                    </div>}

                    <div className="d-flex mt-2 font-8">
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Email</span>
                            <span>{(book.Guest || book.Coorp).Email}</span>
                        </figure>
                    </div>
                    <div className="d-flex mt-2">
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0" style={{ minHeight: 50 }}>
                            <div className="d-flex flex-row">
                                <span className="w-50">Acompañantes/Party ({target_room.COMPANIONS.length})</span>
                                <span className="w-25">Edad/Age</span>
                                <span className="w-25">Cédula/Passport</span>
                            </div>
                            {target_room.COMPANIONS.map((comp, index) => <div className="d-flex flex-row" key={index}>
                                <span className="w-50">{`${comp.FirstName} ${comp.LastName}`}</span>
                                <span className="w-25">{comp.DateOfBirth ? moment().diff(moment(comp.DateOfBirth.split('T')[0], 'YYYY-MM-DD'), 'years') : '-'}</span>
                                <span className="w-25">{comp.IdOrPassport}</span>
                            </div>)}

                        </figure>
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0" style={{ minHeight: 50 }}>
                            <span>Actualización de datos/Info. update</span>
                            <span>Dirección/Address:</span>
                            <span className="mt-4">Teléfono/Phone number:</span>
                            <span>Email:</span>
                        </figure>
                    </div>
                    {/* REGLAMENTO */}
                    <div className="d-flex mt-2">
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>REGLAMENTO PARA EL USO DE INSTALACIONES/REGUILATION OF USE OF THE FACILITIES</span>
                            <ul className="font-6 m-0">
                                <li className="line1">1. The compny's rooms are fully equiped with items to make your stay more enjoyable. Any damage incurred by the guest, such as lost of ramaged furniture, equipment or other effects belonging to the Hotel, will be the sole responsibility of the guest. If damage or loss occurs, the Hotel will have no other alternative than to charge the guest makrget value for repairing and/or replacing the item(s) discovered upon inventory.</li>
                                <li className="line1">2. The company reserves th right to remove all belongings and luggage from the room and hold them at the front desk if they remain after the agreed check-out time in order to accomodate the next confirmed reservation.</li>
                                <li className="line1">3. The company will not be responsible for money, jewlery or other items of value left in the room or at your facility.</li>
                                <li className="line1">4. PETS ARE NOT ALLOWED. Excessive noise or altercations of any kind, the use of devices or tranferring the room(s) to third parties is strictly forbidden.</li>
                                <li className="line1">5. Our accounts are due daily.</li>
                                <li className="line1">6. Guests agree to leave an open credit card voucher at the reception for administrative control.</li>
                                <li className="line1">7. The hotel is not responsible for accidents occurring on its premises or those caused by carelessness, neglect or any in which a warning was given by the hotel staff ,and neglected by visitors and/or guests. In addition, the hotel is not responsible for any electrical, mechanical and hydraulic system incidents or ensuing accidents that may occur.</li>
                                <li className="line1">8. Visitors are allowed only in the hotel's public areas.</li>
                                <li className="line1">9. We recommend that you arrive early in order to verify the inventory of your room before check-in and at the time of check-out, as this will be inventoried by the hotel staff and is suspect to the first caption of this regulation.</li>
                                <li className="line1">10. Smoking is not permitted in any of the rooms.</li>
                                <li className="line1">11. All complaints, suggestions or compliments should be reported in a book located at the front desk.</li>
                                <li className="line1">12. Check-in time is {property.CheckIn}</li>
                                <li className="line1">13. Check-out time is {property.CheckOut}</li>
                                <li className="line1">14. 30 minutes after {property.CheckOut} there will bne a surchage of 50% of the total cost of the room. (LATE-CHECK-OUT).</li>
                                <li className="line1">15. The room key must be delivered to the front desk at the time of check-out and final bill is processed.</li>
                                <li className="line1">16. At check-out time, the hotel staff will verify that all items in the room (such as towels, hand towels, floor towelette, etc) are present while the guest remains at the front desk. All information relating to guests, employees, visitors and facilities of Hotel is confidential.</li>
                                <li className="line1">17. With respect to vehicles entering the premises of Hotel: The company is not responsible for valuables of damage to vehicles. Hotel Management grants the power to verify the status of these vehicles with the assistance of security personnel.</li>
                                <li className="line1">18. Guests staying oat our facility, who have cars must provide its details to the front desk during check-in in order to prevent possible towing and/or ticketing after hours.</li>

                                <li className="line1">1. Las habitaciones de la empresa están completamente equipadas con artículos para hacer su estadía más placentera. Cualquier daño incurrido por el huésped, como pérdida o daño de muebles, equipos u otros efectos pertenecientes al Hotel, será responsabilidad exclusiva del huésped. Si ocurre daño o pérdida, el Hotel no tendrá otra alternativa que cobrar al huésped el valor de mercado para reparar y/o reemplazar el(los) artículo(s) descubierto(s) en el inventario.</li>
                                <li className="line1">2. La empresa se reserva el derecho de retirar todas las pertenencias y equipaje de la habitación y mantenerlas en la recepción si permanecen después de la hora de salida acordada para acomodar la próxima reserva confirmada.</li>
                                <li className="line1">3. La empresa no será responsable por dinero, joyas u otros artículos de valor dejados en la habitación o en sus instalaciones.</li>
                                <li className="line1">4. NO SE PERMITEN MASCOTAS. El ruido excesivo o altercados de cualquier tipo, el uso de dispositivos o la transferencia de la(s) habitación(es) a terceros está estrictamente prohibido.</li>
                                <li className="line1">5. Nuestras cuentas son diarias.</li>
                                <li className="line1">6. Los huéspedes aceptan dejar un vale de tarjeta de crédito abierto en la recepción para control administrativo.</li>
                                <li className="line1">7. El hotel no es responsable por accidentes ocurridos en sus instalaciones o aquellos causados por descuido, negligencia o cualquier en el que se haya dado una advertencia por parte del personal del hotel y haya sido ignorada por los visitantes y/o huéspedes. Además, el hotel no es responsable por incidentes eléctricos, mecánicos e hidráulicos o accidentes resultantes que puedan ocurrir.</li>
                                <li className="line1">8. Los visitantes solo están permitidos en las áreas públicas del hotel.</li>
                                <li className="line1">9. Recomendamos que llegue temprano para verificar el inventario de su habitación antes del check-in y en el momento del check-out, ya que será inventariado por el personal del hotel y está sujeto a la primera cláusula de este reglamento.</li>
                                <li className="line1">10. No se permite fumar en ninguna de las habitaciones.</li>
                                <li className="line1">11. Todas las quejas, sugerencias o felicitaciones deben ser reportadas en un libro ubicado en la recepción.</li>
                                <li className="line1">12. La hora de check-in es {property.CheckIn}</li>
                                <li className="line1">13. La hora de check-out es {property.CheckOut}</li>
                                <li className="line1">14. 30 minutos después de {property.CheckOut} habrá un recargo del 50% del costo total de la habitación. (LATE-CHECK-OUT).</li>
                                <li className="line1">15. La llave de la habitación debe ser entregada en la recepción al momento del check-out y se procesará la factura final.</li>
                                <li className="line1">16. Al momento del check-out, el personal del hotel verificará que todos los artículos en la habitación (como toallas, toallas de mano, toalla de piso, etc.) estén presentes mientras el huésped permanece en la recepción. Toda la información relacionada con huéspedes, empleados, visitantes e instalaciones del Hotel es confidencial.</li>
                                <li className="line1">17. Con respecto a los vehículos que ingresan a las instalaciones del Hotel: La empresa no es responsable por objetos de valor o daños a los vehículos. La Administración del Hotel otorga el poder de verificar el estado de estos vehículos con la asistencia del personal de seguridad.</li>
                                <li className="line1">18. Los huéspedes que se alojen en nuestras instalaciones y tengan automóviles deben proporcionar sus detalles a la recepción durante el check-in para evitar posibles remolques y/o multas después de horas.</li>
                            </ul>
                        </figure>
                    </div>
                    <div className="d-flex mt-1">
                        <i className="w-100" />
                        <figure className="card tile d-flex w-100 flex-column p-2 m-0">
                            <span>Firma y fecha/Signature and Date</span>
                            <div className="w-100 py-2" style={{ borderBottom: '1px solid black' }} />
                        </figure>
                    </div>
                </section>
            </section>}
        </section>
    }
}
export default PrintableRegistration;